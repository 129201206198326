import React from "react";
import Lottie from "lottie-react";
import loading from "./loading.json";

const Loading: React.FC = () => {
  return (
    <div className="loaderParent">
      <div className="loader">
        <Lottie animationData={loading} loop={true} />
      </div>
    </div>
  );
};

export default Loading;
