import React from "react";

const Header: React.FC = () => {
  return (
    <header>
      <div className="Soueezr">
        <svg
          width="190"
          height="46"
          viewBox="0 0 190 46"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.0001 18L55.0001 42L51.4646 45.5355L27.4646 21.5355L31.0001 18Z"
            fill="#0FB597"
          />
          <path
            d="M174.947 26.44H169.251V45H163.683V0.200012H175.139C178.765 0.200012 181.496 1.01068 183.331 2.63201C185.208 4.21068 186.147 6.72801 186.147 10.184V16.328C186.147 21.1493 184.227 24.1573 180.387 25.352L189.283 45H183.203L174.947 26.44ZM180.579 9.60801C180.579 7.98668 180.195 6.81335 179.427 6.08801C178.701 5.36268 177.528 5.00002 175.907 5.00002H169.251V21.64H175.907C177.528 21.64 178.701 21.2773 179.427 20.552C180.195 19.784 180.579 18.5893 180.579 16.968V9.60801Z"
            fill="black"
          />
          <path
            d="M142.783 40.264H158.591V45H137.087V39.368L153.599 4.93601H138.559V0.200012H159.231V5.83201L142.783 40.264Z"
            fill="black"
          />
          <path
            d="M113.238 45V0.200012H134.23V4.87201H118.806V19.528H132.63V24.2H118.806V40.328H134.23V45H113.238Z"
            fill="black"
          />
          <path
            d="M87.4675 45V0.200012H108.46V4.87201H93.0355V19.528H106.86V24.2H93.0355V40.328H108.46V45H87.4675Z"
            fill="black"
          />
          <path
            d="M81.3774 34.12C81.3774 37.7467 80.3748 40.4773 78.3694 42.312C76.4068 44.104 73.5694 45 69.8574 45H68.8974C65.1854 45 62.3268 44.104 60.3214 42.312C58.3588 40.4773 57.3774 37.7467 57.3774 34.12V0.200012H62.9454V35.336C62.9454 36.6587 63.4361 37.7893 64.4174 38.728C65.3988 39.6667 66.6788 40.136 68.2574 40.136H70.4974C72.0761 40.136 73.3561 39.6667 74.3374 38.728C75.3188 37.7893 75.8094 36.6587 75.8094 35.336V0.200012H81.3774V34.12Z"
            fill="black"
          />
          <path
            d="M51.301 34.12C51.301 37.7467 50.2983 40.4773 48.293 42.312C46.3303 44.104 43.493 45 39.781 45H37.605C33.893 45 31.0343 44.104 29.029 42.312C27.0663 40.4773 26.085 37.7467 26.085 34.12V11.08C26.085 7.45335 27.0663 4.74401 29.029 2.95201C31.0343 1.11735 33.893 0.200012 37.605 0.200012H39.781C43.493 0.200012 46.3303 1.11735 48.293 2.95201C50.2983 4.74401 51.301 7.45335 51.301 11.08V34.12ZM45.733 9.86401C45.733 8.54135 45.2423 7.41068 44.261 6.47201C43.2796 5.53335 41.9996 5.06401 40.421 5.06401H36.965C35.3863 5.06401 34.1063 5.53335 33.125 6.47201C32.1436 7.41068 31.653 8.54135 31.653 9.86401V35.336C31.653 36.6587 32.1436 37.7893 33.125 38.728C34.1063 39.6667 35.3863 40.136 36.965 40.136H40.421C41.9996 40.136 43.2796 39.6667 44.261 38.728C45.2423 37.7893 45.733 36.6587 45.733 35.336V9.86401Z"
            fill="black"
          />
          <path
            d="M21.576 34.12C21.576 37.7467 20.5734 40.4773 18.568 42.312C16.6054 44.104 13.768 45 10.056 45H1.60802V40.136H10.696C12.2747 40.136 13.5547 39.6667 14.536 38.728C15.5174 37.7893 16.008 36.6587 16.008 35.336V28.68C16.008 27.4 15.6667 26.376 14.984 25.608C14.344 24.7973 13.3627 24.392 12.04 24.392H10.76C7.60269 24.392 5.10669 23.4747 3.27202 21.64C1.43735 19.7627 0.52002 17.0533 0.52002 13.512V11.08C0.52002 7.45335 1.50135 4.74401 3.46402 2.95201C5.46935 1.11735 8.32802 0.200012 12.04 0.200012H20.552V5.06401H11.4C9.82135 5.06401 8.54135 5.53335 7.56002 6.47201C6.57869 7.41068 6.08802 8.54135 6.08802 9.86401V14.728C6.08802 16.0507 6.51469 17.16 7.36802 18.056C8.22135 18.9093 9.33069 19.336 10.696 19.336H11.976C15.0054 19.336 17.352 20.2107 19.016 21.96C20.7227 23.7093 21.576 26.3547 21.576 29.896V34.12Z"
            fill="black"
          />
        </svg>
      </div>
      <div className="">
        <svg
          width="149"
          height="21"
          viewBox="0 0 149 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_5_273)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.46952 5.61877V7.33207C4.18617 6.10637 5.32887 5.49362 6.8984 5.49362C8.19585 5.49362 9.24716 5.93938 10.0531 6.83071C10.8564 7.72164 11.2593 8.93319 11.2593 10.465V17.2742H7.811V10.9453C7.811 10.1935 7.61791 9.61195 7.23211 9.20077C6.84631 8.79057 6.31551 8.58468 5.64007 8.58468C4.96425 8.58468 4.43306 8.79057 4.04727 9.20077C3.66185 9.61195 3.46952 10.1935 3.46952 10.9453V17.2742H0V5.61877H3.46952Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.0978 13.6189C21.5568 13.1172 21.7871 12.3938 21.7871 11.4463C21.7871 10.4998 21.5517 9.77937 21.0773 9.28469C20.6042 8.7906 20.0428 8.54306 19.3935 8.54306C18.744 8.54306 18.1867 8.7906 17.7204 9.28469C17.2533 9.77937 17.0207 10.4998 17.0207 11.4463C17.0207 12.3938 17.2602 13.1172 17.7404 13.6189C18.2212 14.1201 18.7858 14.3708 19.434 14.3708C20.0838 14.3708 20.6375 14.1201 21.0978 13.6189ZM15.2047 15.7697C14.0612 14.6704 13.4907 13.2215 13.4907 11.4253C13.4907 9.62908 14.0585 8.1914 15.1946 7.11266C16.3308 6.03352 17.7372 5.49365 19.4147 5.49365C21.0911 5.49365 22.4948 6.03352 23.624 7.11266C24.7527 8.1914 25.3171 9.62908 25.3171 11.4253C25.3171 13.2215 24.7558 14.6704 23.6337 15.7697C22.5114 16.8701 21.1104 17.4204 19.434 17.4204C17.7572 17.4204 16.3476 16.8701 15.2047 15.7697Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M31.1187 5.61877V7.33207C31.835 6.10637 32.9787 5.49362 34.5471 5.49362C35.3849 5.49362 36.132 5.6889 36.7892 6.07847C37.4446 6.46805 37.9548 7.0254 38.3201 7.74954C38.7393 7.06685 39.2844 6.52031 39.9532 6.10991C40.6233 5.69912 41.3695 5.49362 42.1947 5.49362C43.5752 5.49362 44.6696 5.93231 45.4817 6.8093C46.2926 7.68667 46.6988 8.90549 46.6988 10.465V17.2742H43.2499V10.9453C43.2499 10.1935 43.0575 9.61195 42.6715 9.20077C42.2863 8.79057 41.7546 8.58468 41.0791 8.58468C40.402 8.58468 39.8713 8.79057 39.4861 9.20077C39.1011 9.61195 38.9088 10.1935 38.9088 10.9453V17.2742H35.4606V10.9453C35.4606 10.1935 35.2671 9.61195 34.8819 9.20077C34.4967 8.79057 33.9655 8.58468 33.2891 8.58468C32.6133 8.58468 32.0825 8.79057 31.6967 9.20077C31.3118 9.61195 31.1187 10.1935 31.1187 10.9453V17.2742H27.6494V5.61877H31.1187Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M50.3844 3.74705V2.46222L53.4935 2.46182V5.61869H55.8324V8.58461H53.4935V13.4308C53.4935 13.9742 53.8458 14.2455 54.5489 14.2455H55.7866V17.2741H54.0213C52.7504 17.2741 51.7662 16.9849 51.0696 16.4069C50.3732 15.8292 50.0252 14.8374 50.0252 13.4308V8.58461H48.2812L48.2826 5.61634L48.8827 5.61869C49.5669 5.61869 50.3844 5.13364 50.3844 3.74705Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M64.5495 9.05495C64.1433 8.7139 63.6632 8.54299 63.1086 8.54299C62.5551 8.54299 62.0882 8.7139 61.7091 9.05495C61.3309 9.39581 61.0943 9.83823 60.9993 10.381H65.2396C65.1852 9.83823 64.9553 9.39581 64.5495 9.05495ZM63.0685 17.4203C61.4179 17.4203 60.0664 16.8739 59.0111 15.7805C57.957 14.6872 57.4292 13.236 57.4292 11.4252C57.4292 9.61545 57.9528 8.17463 59.0018 7.10217C60.0487 6.02971 61.4053 5.49377 63.0685 5.49377C64.7329 5.49377 66.1118 6.02264 67.2062 7.08095C68.302 8.13946 68.8505 9.60837 68.8505 11.4881C68.8505 11.8922 68.8231 12.2684 68.7689 12.6161H60.9787C61.0462 13.1312 61.2696 13.5526 61.6489 13.8799C62.0277 14.2072 62.4358 14.3707 62.876 14.3707C63.3146 14.3707 63.6436 14.3257 63.8603 14.2353C64.0761 14.1444 64.2341 14.0536 64.3368 13.963C64.4377 13.8729 64.5693 13.7232 64.7329 13.5139H68.4851C68.1606 14.6842 67.5117 15.6272 66.5376 16.3445C65.5636 17.0622 64.4072 17.4203 63.0685 17.4203Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M74.6512 17.2742H71.1829V1.81735H74.6512V10.4858L78.1612 5.6188H82.8274L77.979 11.5718L82.8274 17.2742H78.2221L74.6512 12.6162V17.2742Z"
              fill="black"
            />
          </g>
          <path
            d="M86.2322 20.3544L94.1206 0H95.7527L87.8643 20.3544H86.2322Z"
            fill="black"
          />
          <path
            d="M99.2301 15.6851H103.31V1.99464H99.2301V0.453328H105.124V15.6851H109.204V17.2264H99.2301V15.6851Z"
            fill="black"
          />
          <path
            d="M121.862 17.2264C121.152 17.2264 120.645 17.0451 120.343 16.6824C120.041 16.3198 119.852 15.8664 119.776 15.3225H119.663C119.406 16.0024 118.991 16.5389 118.416 16.9318C117.857 17.3095 117.102 17.4984 116.149 17.4984C114.925 17.4984 113.951 17.1811 113.225 16.5464C112.5 15.9118 112.137 15.0429 112.137 13.9398C112.137 12.8518 112.53 12.0132 113.316 11.4238C114.117 10.8345 115.401 10.5399 117.17 10.5399H119.663V9.38387C119.663 8.52255 119.421 7.88033 118.938 7.45723C118.454 7.01901 117.766 6.79991 116.875 6.79991C116.089 6.79991 115.447 6.95857 114.948 7.2759C114.449 7.57812 114.049 7.99367 113.747 8.52255L112.523 7.61589C112.674 7.31368 112.878 7.01901 113.135 6.73191C113.392 6.4448 113.709 6.19547 114.087 5.98392C114.465 5.75725 114.895 5.58348 115.379 5.46259C115.862 5.32659 116.399 5.25859 116.988 5.25859C118.363 5.25859 119.451 5.60614 120.252 6.30125C121.068 6.99635 121.476 7.96344 121.476 9.20254V15.6398H123.109V17.2264H121.862ZM116.467 15.9798C116.935 15.9798 117.358 15.9269 117.736 15.8211C118.129 15.7002 118.469 15.5491 118.756 15.3678C119.043 15.1713 119.263 14.9447 119.414 14.6878C119.58 14.4158 119.663 14.1287 119.663 13.8265V11.8998H117.17C116.081 11.8998 115.288 12.0509 114.789 12.3532C114.291 12.6554 114.041 13.0936 114.041 13.6678V14.1438C114.041 14.7482 114.253 15.2091 114.676 15.5264C115.114 15.8287 115.711 15.9798 116.467 15.9798Z"
            fill="black"
          />
          <path
            d="M126.019 0.453328H127.833V7.43456H127.923C128.679 5.98392 129.88 5.25859 131.528 5.25859C132.963 5.25859 134.081 5.79503 134.882 6.8679C135.698 7.94078 136.106 9.44431 136.106 11.3785C136.106 13.3127 135.698 14.8162 134.882 15.8891C134.081 16.962 132.963 17.4984 131.528 17.4984C129.88 17.4984 128.679 16.7731 127.923 15.3225H127.833V17.2264H126.019V0.453328ZM130.825 15.8891C131.883 15.8891 132.699 15.5718 133.273 14.9371C133.862 14.2874 134.157 13.4336 134.157 12.3758V10.3812C134.157 9.32343 133.862 8.47721 133.273 7.84256C132.699 7.19279 131.883 6.8679 130.825 6.8679C130.417 6.8679 130.031 6.92079 129.669 7.02657C129.306 7.13234 128.989 7.29101 128.717 7.50256C128.445 7.699 128.226 7.94833 128.059 8.25055C127.908 8.53766 127.833 8.87765 127.833 9.27054V13.4865C127.833 13.8794 127.908 14.2269 128.059 14.5291C128.226 14.8162 128.445 15.0656 128.717 15.2771C128.989 15.4736 129.306 15.6247 129.669 15.7304C130.031 15.8362 130.417 15.8891 130.825 15.8891Z"
            fill="black"
          />
          <path
            d="M144.027 17.4984C142.803 17.4984 141.745 17.2944 140.853 16.8864C139.977 16.4633 139.229 15.9042 138.609 15.2091L139.833 14.1438C140.392 14.7331 141.004 15.1865 141.669 15.5038C142.334 15.8211 143.135 15.9798 144.072 15.9798C144.979 15.9798 145.719 15.8211 146.293 15.5038C146.883 15.1713 147.178 14.6576 147.178 13.9625C147.178 13.6603 147.117 13.4109 146.996 13.2145C146.89 13.0029 146.739 12.8367 146.543 12.7158C146.346 12.5949 146.127 12.5043 145.885 12.4438C145.644 12.3683 145.387 12.3078 145.115 12.2625L143.279 11.9905C142.871 11.9301 142.425 11.8469 141.941 11.7412C141.473 11.6354 141.042 11.4692 140.649 11.2425C140.256 11.0007 139.924 10.6834 139.652 10.2905C139.395 9.89764 139.266 9.38387 139.266 8.74921C139.266 8.15989 139.38 7.65367 139.606 7.23057C139.848 6.79235 140.181 6.42969 140.604 6.14258C141.027 5.84036 141.526 5.62125 142.1 5.48526C142.674 5.33415 143.301 5.25859 143.981 5.25859C145.039 5.25859 145.953 5.42481 146.724 5.75725C147.51 6.08969 148.182 6.55058 148.742 7.1399L147.563 8.25055C147.427 8.08433 147.253 7.91811 147.042 7.75189C146.845 7.57056 146.596 7.4119 146.293 7.2759C146.006 7.12479 145.666 7.0039 145.273 6.91324C144.881 6.82257 144.427 6.77724 143.913 6.77724C142.992 6.77724 142.281 6.9359 141.783 7.25323C141.299 7.57056 141.057 8.03144 141.057 8.63588C141.057 8.9381 141.11 9.19498 141.216 9.40653C141.337 9.60298 141.495 9.76164 141.692 9.88253C141.888 10.0034 142.107 10.1016 142.349 10.1772C142.606 10.2376 142.863 10.2905 143.12 10.3359L144.956 10.6079C145.379 10.6683 145.825 10.7514 146.293 10.8572C146.762 10.963 147.193 11.1367 147.586 11.3785C147.978 11.6052 148.303 11.9149 148.56 12.3078C148.832 12.7007 148.968 13.2145 148.968 13.8491C148.968 15.0127 148.515 15.9118 147.608 16.5464C146.717 17.1811 145.523 17.4984 144.027 17.4984Z"
            fill="black"
          />
          <defs>
            <clipPath id="clip0_5_273">
              <rect
                width="82.8714"
                height="15.6293"
                fill="white"
                transform="translate(0 1.81735)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </header>
  );
};

export default Header;
