import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import SearchBar from "./components/search/SearchBar";
import NewsList from "./components/search/NewsList";
import Loading from "./components/search/Loading";
import LoginComponent from "./components/Login";
import Settings from "./components/Settings";
import "./App.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import OAuthCallbackHandler from "./auth/OAuthCallbackHandler";
import { useAuthToken } from "./auth/auth";

interface NewsItem {
  title: string;
  date: string;
  source: string;
  summary: string;
  imageUrl: string;
  keywords: string[];
  ttr: number;
  link: string;
}

function App() {
  const [news, setNews] = useState<NewsItem[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const handleSearch = (
    data: {
      image: string;
      link: string;
      publicationDate: string;
      title: string;
      description: string;
      summary: string;
      source: string;
      ttr: number;
      keywords: string[];
      pageContentList: { text: string }[];
    }[]
  ): void => {
    // Here you would call an API to fetch the news based on the searchTerm
    setNews(
      data.map((item) => ({
        title: item.title,
        date: item.publicationDate,
        source: item.source,
        keywords: item.keywords,
        ttr: item.ttr,
        summary: (item.summary || item.description || "").slice(650),
        imageUrl: item.image,
        link: item.link,
      }))
    );
  };

  const handleLoading = (isLoading: boolean): void => {
    setLoading(isLoading);
    // Here you would set some state to show a loading indicator to the user
  };

  const token = useAuthToken();
  useEffect(() => {
    //if token is empty redirect to login
    if (!token && window.location.pathname === "/") {
      window.location.href = "/login";
    }
  }, [token]);

  return (
    <div className="app">
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Settings />
                <Header />
                <SearchBar onSearch={handleSearch} onLoading={handleLoading} />
                {isLoading && <Loading />}
                {!isLoading && <NewsList news={news} />}{" "}
              </>
            }
          />
          <Route path="/callback" element={<OAuthCallbackHandler />} />
          <Route
            path="/login"
            element={
              <>
                <Header />
                <LoginComponent />
              </>
            }
          />
          {/* Add more routes as needed */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
