// src/components/OAuthCallbackHandler.js
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { handleAuthRedirect } from "./auth";

const OAuthCallbackHandler = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // The handleAuthRedirect function would be called here to handle the "code" exchange
    handleAuthRedirect()
      .then(() => {
        // After successful authentication and token exchange, redirect the user to a dashboard or home page
        navigate("/"); // Adjust the path as needed
      })
      .catch((error) => {
        console.error("Authentication error:", error);
        navigate("/"); // Redirect to home page or error page as fallback
      });
  }, [navigate]);

  return <div>Processing your sign-in...</div>;
};

export default OAuthCallbackHandler;
