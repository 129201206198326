import React from "react";
import { DateTime } from "luxon";
import { motion } from "framer-motion";

const parsePlaceholder = (category: string) => {
  switch (category) {
    case "AI": {
      return "./category_ai.jpg";
    }
    case "GenAI": {
      return "./category_gen_ai.jpg";
    }
    case "Augmented Reality": {
      return "./category_ar.jpg";
    }
    case "Backend": {
      return "./category_backend.jpg";
    }
    case "Computer Vision": {
      return "./category_cv.jpg";
    }
    case "Funny": {
      return "./category_funny.jpg";
    }
    case "Mobile Apps": {
      return "./category_mobile.jpg";
    }
    case "New Trends": {
      return "./category_new_trends.jpg";
    }
    case "Product Development": {
      return "./category_product.jpg";
    }
    case "Virtual Reality": {
      return "./category_vr.jpg";
    }
    default: {
      return "./category_funny.jpg";
    }
  }
};

interface NewsItemProps {
  isFirst?: boolean;
  item: {
    title: string;
    date: string;
    source: string;
    summary: string;
    imageUrl: string;
    keywords: string[];
    ttr: number;
    link: string;
  };
}
//parse time to read in seconds to string in format "x min read"
const parseTtr = (ttr: number) => {
  if (ttr <= 0) {
    return "";
  }
  const minutes = Math.ceil(ttr / 60);
  return `${minutes} min read`;
};

const NewsItem: React.FC<NewsItemProps> = ({
  item: { title, summary, source, date, imageUrl, keywords, ttr, link },
  isFirst = false,
}) => {
  const itemClass = isFirst ? "news-item first" : "news-item";
  return (
    <motion.div
      whileHover={{ scale: [null, 1.01, 1.01] }}
      transition={{ duration: 0.3 }}
      className={itemClass}
      onClick={() => {
        window.open(link, "_blank");
      }}
    >
      <img
        src={imageUrl || parsePlaceholder(keywords[0])}
        alt={title}
        className={isFirst ? "news-image first" : "news-image"}
        onError={(event) => {
          (event.target as any).src = parsePlaceholder(keywords[0]);
          (event.target as any).onerror = null;
        }}
      />
      <div className="news-details">
        <span className="news-top">
          <span className="news-keywords">{keywords.join(" / ")}</span>
          {ttr > 0 && <>•&nbsp;{parseTtr(ttr)} </>}
        </span>
        <p className="news-title">{title}</p>
        <p className="news-summary">{summary}</p>
        <div className="news-meta">
          <span className="news-bottom">
            {" "}
            {!!date ??
              DateTime.fromISO(date).toLocaleString({
                month: "short",
                day: "numeric",
                year: "numeric",
              })}{" "}
            {!!date ?? "•"}
            &nbsp;{source}
          </span>
        </div>
      </div>
    </motion.div>
  );
};

export default NewsItem;
