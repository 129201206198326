import { handleRefresh } from "../auth/auth";

const url = process.env.REACT_APP_API_URL;

export const getExtendedSearchQuery = async (
  searchTerm: string,
  shouldRefresh = true
) => {
  const findResults = async () => {
    return await fetch(
      `${url}/api/search/extended?query=${encodeURIComponent(searchTerm)}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("sq.at")}`,
        },
      }
    );
  };
  let response = await findResults();
  if (response.status === 401 && shouldRefresh) {
    await handleRefresh();
    response = await findResults();
  }

  if (!response.ok) {
    throw new Error("Search failed");
  }

  const data = await response.json();
  return data;
};

export const getBasicSearchQuery = async (
  searchTerm: string,
  shouldRefresh = true
) => {
  let response = await fetch(
    `${url}/api/search?query=${encodeURIComponent(searchTerm)}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("sq.at")}`,
      },
    }
  );
  if (response.status === 401 && shouldRefresh) {
    await handleRefresh();
    response = await fetch(
      `${url}/api/search?query=${encodeURIComponent(searchTerm)}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("sq.at")}`,
        },
      }
    );
  }

  if (!response.ok) {
    throw new Error("Search failed");
  }
  const data = await response.json();
  return data;
};

export const getUserKeywordsAndInterests = async () => {
  const fetchData = () =>
    Promise.all([
      fetch(url + "/api/users/interests", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("sq.at")}`,
        },
      }),
      fetch(url + "/api/users/favorite-keywords", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("sq.at")}`,
        },
      }),
      fetch(url + "/api/keywords", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("sq.at")}`,
        },
      }),
    ]);

  let [userInterests, userKeywords, allKeywords] = await fetchData();

  if (
    userInterests.status === 401 ||
    userKeywords.status === 401 ||
    allKeywords.status === 401
  ) {
    await handleRefresh();
    [userInterests, userKeywords, allKeywords] = await fetchData();
  }
  const userInterestsJson = await userInterests.json();
  const userKeywordsJson = await userKeywords.json();
  const allKeywordsJson = await allKeywords.json();
  return {
    userInterests: userInterestsJson,
    userKeywords: userKeywordsJson,
    allKeywords: allKeywordsJson,
  };
};

export const removeUserInterest = async (interestId: string) => {
  //DELETE /api/users/interests/:id
  //Auth bearer Token from localStorage.getItem("sq.at")

  const response = await fetch(`${url}/api/users/interests/${interestId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("sq.at")}`,
    },
  });
  if (response.status === 401) {
    await handleRefresh();
    await fetch(`${url}/api/users/interests/${interestId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("sq.at")}`,
      },
    });
  }
};

export const addUserInterest = async (
  interest: string,
  shouldRefresh = true
) => {
  //POST /api/users/interests body: name
  //Auth bearer Token from localStorage.getItem("sq.at")

  const response = await fetch(url + "/api/users/interests", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("sq.at")}`,
    },
    body: JSON.stringify({ name: interest }),
  });
  if (response.status === 401 && shouldRefresh) {
    //refresh token
    await handleRefresh();
    addUserInterest(interest, false);
  }
  return await response.text();
};

export const removeKeywordFromFavorites = async (
  id: string,
  shouldRefresh = true
) => {
  //POST /api/users/favorite-keywords
  //Auth bearer Token from localStorage.getItem("sq.at")

  const response = await fetch(`${url}/api/users/favorite-keywords/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("sq.at")}`,
    },
  });
  if (response.status === 401 && shouldRefresh) {
    //refresh token
    await handleRefresh();
    removeKeywordFromFavorites(id, false);
  }
};

export const addKeywordToFavorites = async (
  id: string,
  shouldRefresh = true
) => {
  //POST /api/users/favorite-keywords
  //Auth bearer Token from localStorage.getItem("sq.at")

  const response = await fetch(url + "/api/users/favorite-keywords", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("sq.at")}`,
    },
    body: JSON.stringify({ keywordId: id }),
  });
  if (response.status === 401 && shouldRefresh) {
    //refresh token
    await handleRefresh();
    addKeywordToFavorites(id, false);
  }
  return await response.text();
};
