import React, { useEffect, useState } from "react";
import {
  addKeywordToFavorites,
  addUserInterest,
  getUserKeywordsAndInterests,
  removeKeywordFromFavorites,
  removeUserInterest,
} from "../api";

const InterestModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const fetchData = async () => {
    try {
      const { userInterests, userKeywords, allKeywords } =
        await getUserKeywordsAndInterests();
      setKeywords(
        allKeywords.map((item: any) => ({
          id: item.id,
          name: item.keywordTitle,
        }))
      );

      setFavoriteKeywords(
        userKeywords.map((item: any) => ({
          id: item.id,
          keywordId: item.keyword.id,
          name: item.keyword.keywordTitle,
        }))
      );

      setCustomInterests(
        userInterests.map((item: any) => ({ id: item.id, name: item.name }))
      );
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [keywords, setKeywords] = useState<{ id: string; name: string }[]>([]);
  const [favoriteKeywords, setFavoriteKeywords] = useState<
    { id: string; keywordId: string; name: string }[]
  >([]);

  const [customInterests, setCustomInterests] = useState<
    { id: string; name: string }[]
  >([]);
  const [customInterestValue, setCustomInterestValue] = useState<string>("");

  const isInterestSelected = (interest: { id: string; name: string }) => {
    return (
      favoriteKeywords.find((item) => item.keywordId === interest.id) !==
      undefined
    );
  };

  const handleFavoriteKeyword = async (interest: {
    id: string;
    name: string;
  }) => {
    if (!isInterestSelected(interest)) {
      const id = await addKeywordToFavorites(interest.id);
      setFavoriteKeywords([
        ...favoriteKeywords,
        { ...interest, keywordId: interest.id, id: id },
      ]);
    } else {
      setFavoriteKeywords(
        favoriteKeywords.filter((item) => item.keywordId !== interest.id)
      );
      const id = favoriteKeywords.find(
        (item) => item.keywordId === interest.id
      )?.id;
      if (id) {
        removeKeywordFromFavorites(id);
      }
    }
  };

  const handleCustomInterest = async () => {
    if (customInterestValue) {
      //check if the custom interest is already in the list
      if (!customInterests.find((item) => item.name === customInterestValue)) {
        const id = await addUserInterest(customInterestValue);
        setCustomInterests([
          ...customInterests,
          { name: customInterestValue, id: id },
        ]);
      }

      setCustomInterestValue("");
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2 className="modal-content-title">What are you into?</h2>
        <p className="modal-content-text">
          Select the thematic areas of news that interest you most so we will be
          able to customize your feed.
        </p>
        <div className="interests">
          {keywords.map((interest) => (
            <div
              className={`interest-tag ${
                isInterestSelected(interest) ? "selected" : ""
              }`}
              key={interest.id}
              onClick={() => handleFavoriteKeyword(interest)}
            >
              {interest.name}
            </div>
          ))}
          {customInterests.map((interest) => (
            <div
              className="interest-tag selected"
              key={interest.id}
              onClick={() => {
                setCustomInterests(
                  customInterests.filter((item) => item !== interest)
                );
                removeUserInterest(interest.id);
              }}
            >
              {interest.name}
            </div>
          ))}
        </div>
        <div style={{ display: "flex" }}>
          <input
            className="add-interest-input"
            type="text"
            value={customInterestValue}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleCustomInterest();
              }
            }}
            onChange={(event) => setCustomInterestValue(event.target.value)}
            placeholder="Add your own interest (one by one)"
          />
          <button className="add-interest-btn" onClick={handleCustomInterest}>
            + Add
          </button>
        </div>
        <button className="skip-btn" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

const SettingsIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 1L14.7193 3.6307L18.4656 3.10081L19.1193 6.82749L22.4616 8.60081L20.7999 12L22.4616 15.3992L19.1193 17.1725L18.4656 20.8992L14.7193 20.3693L12 23L9.2806 20.3693L5.53431 20.8992L4.8806 17.1725L1.53833 15.3992L3.19995 12L1.53833 8.60081L4.8806 6.82749L5.53431 3.10081L9.2806 3.6307L12 1ZM14.9994 5.61099L16.83 5.35207L17.1494 7.17304L17.3183 8.136L18.1819 8.59422L19.8151 9.46072L19.0031 11.1217L18.5738 12L19.0031 12.8783L19.8151 14.5393L18.1819 15.4058L17.3183 15.864L17.1494 16.827L16.83 18.6479L14.9994 18.389L14.0314 18.2521L13.3287 18.9318L12 20.2173L10.6712 18.9318L9.96852 18.2521L9.0005 18.389L7.16995 18.6479L6.85052 16.827L6.68161 15.864L5.81798 15.4058L4.18484 14.5393L4.99676 12.8783L5.42612 12L4.99676 11.1217L4.18484 9.46072L5.81798 8.59422L6.68161 8.136L6.85052 7.17304L7.16995 5.35207L9.0005 5.61099L9.96852 5.74791L10.6712 5.06815L12 3.78271L13.3287 5.06815L14.0314 5.74791L14.9994 5.61099ZM10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12ZM12 8C9.79088 8 8.00002 9.79086 8.00002 12C8.00002 14.2091 9.79088 16 12 16C14.2092 16 16 14.2091 16 12C16 9.79086 14.2092 8 12 8Z"
        fill="#0FB597"
      />
    </svg>
  );
};

const Loading: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);

  return (
    <>
      <div className="settings" onClick={toggleModal}>
        <SettingsIcon />
        <span className="settingsLabel">Settings</span>
      </div>
      {showModal && (
        <InterestModal
          onClose={() => {
            setShowModal(false);
          }}
        />
      )}
    </>
  );
};

export default Loading;
