import { useEffect, useState } from "react";

// src/utils/auth.js
const generateCodeVerifier = () => {
  const array = new Uint8Array(128);
  window.crypto.getRandomValues(array);
  return btoa(String.fromCharCode.apply(null, Array.from(array)))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
};

const sha256 = async (plain: string) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  const hash = await window.crypto.subtle.digest("SHA-256", data);
  return new Uint8Array(hash);
};

const base64urlencode = (a: any) => {
  let str = "";
  const bytes = new Uint8Array(a);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i]);
  }
  return btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
};

const generateCodeChallenge = async (codeVerifier: string) => {
  const hashed = await sha256(codeVerifier);
  return base64urlencode(hashed);
};

const buildAuthUrl = async () => {
  const codeVerifier = generateCodeVerifier();
  const codeChallenge = await generateCodeChallenge(codeVerifier);
  sessionStorage.setItem("pkce_code_verifier", codeVerifier);

  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const redirectUri = encodeURIComponent(
    `${process.env.REACT_APP_FRONTEND_URL}/callback`
  );
  const scope = encodeURIComponent("email profile openid");
  const responseType = "code";
  return `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&code_challenge=${codeChallenge}&code_challenge_method=S256`;
};

const handleAuthRedirect = async () => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");

  if (code) {
    const codeVerifier = sessionStorage.getItem("pkce_code_verifier");
    const redirectUri = `${process.env.REACT_APP_FRONTEND_URL}/callback`;
    const myApiUrl = `${process.env.REACT_APP_API_URL}/api/auth/google`;

    const details = {
      code,
      redirectUri: redirectUri,
      codeVerifier: codeVerifier,
    };

    try {
      const response = await fetch(myApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(details),
      });

      const responseJson = await response.json();
      //save responseJson to localStorage
      localStorage.setItem("sq.at", responseJson.accessToken);
      localStorage.setItem("sq.rt", responseJson.accessToken);

      sessionStorage.removeItem("pkce_code_verifier");
    } catch (error) {
      console.error("Error during code exchange with backend:", error);
    }
  }
};

const handleRefresh = async () => {
  //get refresh token from localStorage
  const refreshToken = localStorage.getItem("sq.rt");
  const myApiUrl = `${process.env.REACT_APP_FRONTEND_URL}/api/auth/refresh`;
  const details = {
    refreshToken,
  };
  const response = await fetch(myApiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(details),
  });
  if (response.status === 401) {
    //remove tokens
    localStorage.removeItem("sq.at");
    localStorage.removeItem("sq.rt");
  } else {
    const responseJson = await response.json();
    //save responseJson to localStorage
    localStorage.setItem("sq.at", responseJson.accessToken);
    localStorage.setItem("sq.rt", responseJson.accessToken);
  }
};

//custom hook that observe changes on localStorage tokens
const useAuthToken = () => {
  const [token, setToken] = useState(localStorage.getItem("sq.at"));
  useEffect(() => {
    const handleStorage = () => {
      setToken(localStorage.getItem("sq.at"));
    };
    window.addEventListener("storage", handleStorage);
    return () => {
      window.removeEventListener("storage", handleStorage);
    };
  }, []);
  return token;
};

export {
  generateCodeVerifier,
  generateCodeChallenge,
  buildAuthUrl,
  handleAuthRedirect,
  handleRefresh,
  useAuthToken,
};
