import React, { SyntheticEvent, useState } from "react";
import { motion } from "framer-motion";
import { getBasicSearchQuery, getExtendedSearchQuery } from "../../api";

interface SearchBarProps {
  onSearch: (searchTerm: any) => void;
  onLoading: (isLoading: boolean) => void;
}
const SearchBar: React.FC<SearchBarProps> = ({ onSearch, onLoading }) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);

  const [query, setQuery] = useState("");

  const handleExtendedSearch = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    try {
      setLoading(true);
      onLoading(true);

      const data = await getExtendedSearchQuery(searchTerm);
      setQuery(data.query);
      onSearch(data.data);
      setLoading(false);
      onLoading(false);
    } catch (error) {
      console.error("Error during search", error);
    }
  };

  const handleSearch = async (e: React.SyntheticEvent<any>): Promise<void> => {
    e.preventDefault();
    try {
      setLoading(true);
      onLoading(true);

      const data = await getBasicSearchQuery(searchTerm);

      setQuery("");

      onSearch(data); // Assuming onSearch will do something with the data
      setLoading(false);
      onLoading(false);
    } catch (error) {
      console.error("Error during search", error);
      // Handle error here, maybe set some state to show an error message to the user
    }
  };

  //use state for modal
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  const useMyPromptInstadClick = (event: SyntheticEvent) => {
    toggleModal();
    handleSearch(event);
  };
  return (
    <>
      <div className="search-bar">
        <form onSubmit={handleExtendedSearch} className="search-form">
          <input
            type="text"
            className="search-input"
            placeholder="I want to explore news about..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="search-button"
            type="submit"
          >
            <svg
              width="23"
              height="24"
              viewBox="0 0 23 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="mail-send-email-message--send-email-paper-airplane-deliver">
                <path
                  id="Vector 2587"
                  d="M3.66596 4.68414L20.7002 12.3501L3.66662 20.0166L6.22215 12.3501L3.66596 4.68414Z"
                  stroke="white"
                  strokeWidth="1.5"
                />
                <path
                  id="Vector 2588"
                  d="M6.22145 12.3494L12.183 12.3501"
                  stroke="white"
                  strokeWidth="1.5"
                />
              </g>
            </svg>
          </motion.button>
        </form>
        {query && !isLoading && (
          <div onClick={toggleModal} className="prompt-preview">
            Prompt preview
          </div>
        )}
      </div>
      {modal && (
        <div
          className="prompt-modal-overlay"
          onClick={(e) => {
            const target = e.target as HTMLDivElement;

            if (target.className === "prompt-modal-overlay") {
              toggleModal();
            }
          }}
        >
          <div className="prompt-modal-content">
            <span onClick={toggleModal} className="prompt-close-button">
              &times;
            </span>
            <div className="prompt-modal-header">Prompt that we've used:</div>
            <div className="prompt-modal-prompt">{query}</div>
            <div className="prompt-modal-header">Your query:</div>
            <div className="prompt-modal-query">{searchTerm}</div>
            <div className="prompt-modal-button-container">
              <button
                className="prompt-modal-button"
                onClick={useMyPromptInstadClick}
              >
                Search with my query instead
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SearchBar;
