import React from "react";

import { buildAuthUrl } from "../auth/auth";

const GoogleIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.06 12.25C23.06 11.47 22.99 10.72 22.86 10H12.5V14.255H18.42C18.165 15.63 17.39 16.795 16.225 17.575V20.335H19.78C21.86 18.42 23.06 15.6 23.06 12.25Z"
        fill="#4285F4"
      />
      <path
        d="M12.4999 23C15.4699 23 17.9599 22.015 19.7799 20.335L16.2249 17.575C15.2399 18.235 13.9799 18.625 12.4999 18.625C9.63492 18.625 7.20992 16.69 6.34492 14.09H2.66992V16.94C4.47992 20.535 8.19992 23 12.4999 23Z"
        fill="#34A853"
      />
      <path
        d="M6.345 14.09C6.125 13.43 6 12.725 6 12C6 11.275 6.125 10.57 6.345 9.91001V7.06001H2.67C1.925 8.54501 1.5 10.225 1.5 12C1.5 13.775 1.925 15.455 2.67 16.94L6.345 14.09Z"
        fill="#FBBC05"
      />
      <path
        d="M12.4999 5.37501C14.1149 5.37501 15.5649 5.93001 16.7049 7.02001L19.8599 3.86501C17.9549 2.09001 15.4649 1.00001 12.4999 1.00001C8.19992 1.00001 4.47992 3.46501 2.66992 7.06001L6.34492 9.91001C7.20992 7.31001 9.63492 5.37501 12.4999 5.37501Z"
        fill="#EA4335"
      />
    </svg>
  );
};

const Loading: React.FC = () => {
  const handleSignIn = async () => {
    window.location.href = await buildAuthUrl();
  };
  return (
    <div className="loginContainer">
      <button
        className="loginButton"
        onClick={() => {
          handleSignIn();
        }}
      >
        <GoogleIcon />
        <span className="loginText">Login with Google</span>
      </button>
    </div>
  );
};

export default Loading;
