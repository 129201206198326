import React from "react";
import NewsItem from "./NewsItem";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { motion } from "framer-motion";

interface NewsProps {
  title: string;
  date: string;
  source: string;
  summary: string;
  imageUrl: string;
  keywords: string[];
  ttr: number;
  link: string;
}

interface NewsListProps {
  news: NewsProps[];
}

const NewsList: React.FC<NewsListProps> = ({ news }) => {
  return (
    <motion.div layout className="news-list-container">
      {news && news.length > 0 && (
        <ResponsiveMasonry
          className="news-list"
          columnsCountBreakPoints={{ 300: 1, 500: 2, 700: 2, 1000: 3 }}
        >
          <NewsItem item={news[0]} isFirst={true} />
          <Masonry gutter="20px">
            {news.slice(1).map((item, index) => (
              <NewsItem key={index} item={item} isFirst={false} />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      )}
    </motion.div>
  );
};

export default NewsList;
